<template>
  <b-sidebar
      id="edit-madatory-sidebar"
      :visible="isEditMandatorySidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-mandatory-sidebar-active', val)"

  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Mandatory Training
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="clearForm(); hide()"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
      >
        <b-overlay rounded="sm">
          <b-card-body>
            <validation-observer ref="trainingForm">
            <span>
              <div class="ml-2 mt-2">Choose the trainings that are applicable for the staff’s role.</div>
            </span>
              <hr/>

              <div class="ml-2">
                <b-row>
                  <b-col cols="11" class="mb-n1">
                   <span style="margin-left: 6px; font-size: 12px"
                   >Select Training</span
                   >
                  </b-col>
                  <b-col cols="11">
                <span
                    class="ml-2"
                    style="
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #171822;
                  "
                >
                  <b-form-group style="margin-left: 6px">
                    <validation-provider
                        #default="{ errors }"
                        name="Training"
                        rules="required"
                    >
                      <v-select
                          label="name"
                          v-model="selectedTraining"
                          :options="fileTypeOption"
                          :reduce="(fileTypeOption) => fileTypeOption.id"
                          placeholder="Select training"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </span>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="11">
                  <span class="" style="margin-left: 6px; font-size: 12px"
                  >Date of Training</span
                  >

                    <b-form-group
                        class=""
                        style="margin-left: 6px; margin-top: 6px"
                    >
                      <validation-provider
                          name="Start date"
                          rules="required"
                          #default="{ errors }"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-datepicker
                              id="start_date"
                              v-model="form.start_date"
                              :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                              placeholder="Select"
                              start-weekday="1"
                              class="form-control"
                              locale="en-UK"
                              trim
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="8">
                  <span class="" style="margin-left: 6px; font-size: 12px"
                  >Renewal frequency</span
                  >
                    <b-form-group style="margin-left: 6px; margin-top: 6px">
                      <v-select
                          :disabled="renewalDesabled"
                          label="name"
                          v-model="seleceted_renewal"
                          placeholder="Select"
                          :options="renewal_options"
                          :reduce="(renewal_options) => renewal_options.key"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-checkbox
                        @change="changeState"
                        v-if="form.is_non_renewal === 1"
                        :value="form.is_non_renewal"
                        :id="form.id"
                        class=""
                        style="margin-top: 32px"
                        v-model="form.is_non_renewal"
                    >
                      <span class="todo-title">Non-renewal</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                        @change="changeState"
                        v-else
                        class=""
                        style="margin-top: 32px"
                        v-model="form.is_non_renewal"
                    >
                      <span class="todo-title">Non-renewal</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="11">
                   <span class="" style="margin-left: 6px; font-size: 12px"
                   >Select Document</span
                   >
                  </b-col>
                </b-row>
                <b-row>
                  <!-- <b-col cols="11">
                      <b-overlay rounded="sm">
                         <b-form-file
                           ref="image_ref"
                           v-model="uploadedFile"
                           drop-placeholder="Drop file here..."
                           placeholder="Choose a file or drop it here..."
                         />
             </b-overlay>
                 </b-col> -->

                  <b-col cols="11" style="margin-top: 10px; margin-left: 6px">

                    <b-row v-if="uploadedCertificate.name">
                      <b-col cols="6">
                        <div
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            class="text-primary ml-auto mr-2 mt-1"
                            @click="downloadFile(uploadedCertificate)"
                        >
                          View certificate
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            class="text-primary ml-auto mr-2 mt-1"
                            @click="deleteCurrentAndUploadNewDocumentPopup(uploadedCertificate)"
                        >
                          Edit certificate
                        </div>
                      </b-col>
                    </b-row>

                    <a v-else href="#" @click="openUploadDocumentPopup()"
                    >Upload document</a
                    >
                  </b-col>
                </b-row>


              </div>
              <b-row>
              <span class="mr-4 mt-2 ml-3">
                <div class="d-flex mt-1">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      @click="submitForm"
                  >
                    Save
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      class="text-primary ml-1 shadow"
                      type="button"
                      variant="outline-white"
                      @click="clearForm(); hide()"
                  >
                    Cancel
                  </b-button>
                </div>
              </span>
              </b-row>
            </validation-observer>
          </b-card-body>
        </b-overlay>
      </validation-observer>
      <UploadCertificate ref="uploadCertificate" @getDocument="getDocument"/>
    </template>
  </b-sidebar>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormFile,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {apiUrl} from '@/constants/config'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import axios from 'axios'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import UploadCertificate from '@/views/staff-bank/staff-profile/includes/popup/UploadCertificate.vue'
import staffBankAPI from '@/apis/modules/staffBank'
import staffBank from '@/apis/modules/staffBank'

export default {
  mixins: [MomentMixin, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    VBTooltip,
    BFormFile,
    BFormRadioGroup,
    UploadCertificate

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },

  model: {
    prop: 'isEditMandatorySidebarActive',
    event: 'update:is-edit-mandatory-sidebar-active'
  },
  props: {
    isEditMandatorySidebarActive: {
      type: Boolean
    }
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate(minDate.getDate())
    return {
      isActive: false,
      buttonLoading: false,
      required,
      selected_training: '',
      fileTypeOption: [],
      renewalDesabled: false,
      form: {
        user_id: '',
        training_id: '',
        start_date: '',
        expire_date: '',
        training_certificate_id: '',
        is_non_renewal: false
      },
      renewal_options: [
        {
          key: '12',
          value: '12',
          name: '12 Months'
        },
        {
          key: '24',
          value: '24',
          name: '24 Months'
        },
        {
          key: '36',
          value: '36',
          name: '36 Months'
        }
      ],
      seleceted_renewal: 0,
      uploadedCertificate: [],
      selectedTraining: ''

    }
  },
  methods: {

    saveDisable () {
      if (this.selectedTraining) {
        return !this.isActive
      } else {
        return this.isActive === true
      }
    },


    toggle () {
      this.isEditMandatorySidebarActive = false
    },

    async getTrainings () {
      try {
        let respond = (await staffBankAPI.getUserUnAssignTrainings(this.$route.params.id)).data
        this.fileTypeOption = respond.data.map((x) => ({
          id: x.id,
          name: x.name
        }))
      } catch (e) {
      }
      this.loadingModel = false
    },
    async getDocument (respond) {
      if (respond) {
        this.uploadedCertificate = respond
        // this.uploadedCertificate = (await staffBankAPI.getOneCertificate(respond)).data.data[0]
        // console.log(this.uploadedCertificate);
        this.form.training_certificate_id = this.uploadedCertificate.id
      }
    },

    changeState(){
      this.renewalDesabled = !this.renewalDesabled
      this.renewalDesabled === true?this.seleceted_renewal = 0:''
    },
    async openSideBar (item) {
      await this.getTrainings()
      this.fileTypeOption.push(item.selected_training)
      if (item) {
        this.selectedTraining = item.selected_training
        this.form.id = item.id
        this.form.start_date = item.start_date
        this.form.expire_date = item.expire_date
        this.form.is_non_renewal = item.is_non_renewal
        this.renewalDesabled = item.is_non_renewal
        this.form.training_certificate_id = item.training_certificate
            ? item.training_certificate.id
            : null
        this.uploadedCertificate = item.training_certificate
            ? item.training_certificate
            : []
        let year =
            new Date(item.expire_date).getFullYear() -
            new Date(item.start_date).getFullYear()
        this.seleceted_renewal = this.renewal_options.find(
            (e) => e.key * 1 === year * 12
        )

      }
      this.loadingModel = false
    },


    openUploadDocumentPopup () {
      this.$refs.uploadCertificate.open()
    },
    async deleteCurrentAndUploadNewDocumentPopup (item) {
      this.$refs.uploadCertificate.replaceDocument(item)
    },
    async submitForm () {
      try {
        if (await this.$refs.trainingForm.validate()) {
          console.log(this.seleceted_renewal)
          this.form.user_id = this.$route.params.id
          if (typeof this.selectedTraining === 'object') {
            this.form.training_id = this.selectedTraining.id
          } else {
            this.form.training_id = this.selectedTraining
          }
          if (this.renewalDesabled === true) {
            this.form.expire_date = ''
          } else {
            let year = new Date(this.form.start_date).getFullYear()
            let month = new Date(this.form.start_date).getMonth()
            let day = new Date(this.form.start_date).getDate()
            if (typeof this.seleceted_renewal === 'object') {
              year = year + this.seleceted_renewal.key / 12
            } else {
              year = year + this.seleceted_renewal / 12
            }
            console.log(this.seleceted_renewal)
            this.form.expire_date = this.momentFormat(
                new Date(year, month, day),
                'YYYY-MM-DD'
            )
          }

          await staffBankAPI.updateTraining(this.form.id, this.form)
          this.showSuccessMessage('Training update successfully')
          this.clearForm()
        }
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async downloadFile (item) {
      try {
        this.loadingModel = true

        const response = await staffBank.downloadCertificate(item.id)
        axios
            .get(
                `${apiUrl}/settings/training-certificates/${
                    item.id
                }/download?auth_practice_id=${sessionStorage.getItem(
                    'practice_id'
                )}`,
                {
                  // headers{
                  // }
                  responseType: 'arraybuffer',
                  withCredentials: true
                }
            )
            .then((response) => {
              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()
            })
        this.showSuccessMessage('File Downloaded Successfully')
      } catch (error) {
        this.convertAndNotifyError(error)
        this.loadingModel = false
      }
      this.loadingModel = false
    },

    clearForm () {
      this.clearData()
      this.$emit('getAllData')
    },
    clearData () {
      this.form = {}
      this.seleceted_renewal = 0
      this.uploadedCertificate = []
    }

  },

  mounted () {
  }


}
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
