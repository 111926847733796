<template xmlns="http://www.w3.org/1999/html">
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="6" v-if="openEditWorkingHoursForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="CalendarIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Working Hours
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openWorkingHoursForm(isActiveEditWorkingHoursForm)"
              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>

            <div v-for="(day,index) in workingHours" :key="index">
              <b-row class="mt-2" style="margin-left: 5px">
                <b-col cols="4">
                  <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="2" height="18" fill="#00C49A"/>
                  </svg>
                  <span class="ml-1"
                        style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                  {{ day.day }}
                </span>
                </b-col>
                <b-col cols="8">
                <span v-for="(time,index) in day.times" :key="index" class="mr-2"
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                  <feather-icon class="mr-1" icon="ClockIcon"/>
                  <span> {{ time.start + ' - ' + time.end }}</span>
                <span>{{ ' ( ' + time.bMinute + ' mins )' }}</span>
                </span>

                </b-col>
              </b-row>
            </div>

            <div style="height: 38px; background-color: #EBF5FB; margin-top: 30px">
              <span class="m-1">Total hours/week: {{ total.toFixed(1) }} </span>
            </div>
          </b-card-body>

        </b-card>
      </b-col>


      <b-col lg="6" md="6" v-if="openEditWorkingHoursForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="CalendarIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Working Hours
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col class="mt-1" cols="12" md="12">
                <b-form-group
                    label="Days"
                >
                </b-form-group>
              </b-col>
              <b-col class="mb-1" cols="12" md="12">

                <b-button
                    v-for="(day,index) in days"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon rounded-circle "
                    variant="white"
                    :key="index"
                    @click="WorkingHoursToggleDaySelection(day)"
                >
                  <b-avatar
                      :class="isWorkingHourAvailableForThisDay(day.day) ? 'text-white bg-primary ' : 'text-dark border-secondary'"
                      :style="isWorkingHourAvailableForThisDay(day.day) ? 'backgroundColor:#1B9AAA !important' : 'backgroundColor:white'"
                      :text="day.text"/>
                </b-button>
              </b-col>
            </b-row>


            <b-row class="pt-3">
              <b-col v-show="workingHours.length" cols="12" md="12">
                <b-form-group
                    label="Working Hours"
                >
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12" md="12">
              </b-col>
            </b-row>
            <validation-observer
                ref="workingHoursForm"
                #default="{ handleSubmit }"
            >
              <b-form
                  ref="form"
                  class="repeater-form "
              >
                <!-- Add Button -->
                <div v-for="(day,index) in workingHours" :key="index" class="mb-1">
                  <b-row v-for="(time,innerIndex) in day.times" :key="innerIndex">
                    <b-col v-if="innerIndex === 0" class="mt-1" cols="12 pr-0" md="2">
                      <h6>{{ day.day }}</h6>
                    </b-col>
                    <!-- <b-col v-else class="mt-1" cols="12 pr-0" md="2">
                      <h6></h6>
                    </b-col> -->

                    <b-col cols="12" md="3" class="start-time">
                      <div class="set-mb">
                        <validation-provider
                            #default="{ errors }"
                            :vid="'StartTime'+index+innerIndex"
                            name="Start time"
                            rules="required"
                        >
                          <flat-pickr
                              v-model="workingHours[index].times[innerIndex].start"
                              :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                              class="form-control"
                              placeholder="Start time"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                    </b-col>

                    <span class="text-dark font-weight-700" style="margin-top: 5px"> - </span>
                    <b-col class="mb-1" cols="12" md="3">
                      <div class="set-mb">
                        <validation-provider
                            #default="{ errors }"
                            :vid="'EndTime'+index+innerIndex"
                            name="End time"
                            rules="required"
                        >
                          <flat-pickr
                              v-model="workingHours[index].times[innerIndex].end"
                              :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',
                              minDate:workingHours[index].times[innerIndex].start }"
                              class="form-control"
                              placeholder="End time"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <span class="text-dark font-weight-700" style="margin-top: 5px"> - </span>
                    <b-col class="mb-1 mt-n2" style="bg-color: #1B9AAA" cols="12" md="2">
                      <div class="">
                         <span class="mb-1">Break </span>
                        <b-form-input
                            type="number"
                            autocomplete="off"
                            v-model="workingHours[index].times[innerIndex].bMinute"
                            id="h-last-name"
                            class="mb-1"
                        />
                      </div>
                    </b-col>
                    <span class="mins">min   </span>
                    <span><p style=" margin-top: 5px; color: #FFFFFF">aa</p></span>
                    <span><p style=" margin-top: 5px; color: #1B9AAA">{{hoursCalculate(workingHours[index]) }} hrs</p></span>
                    <p class="white">{{ TotalHoursCal(workingHours[index]) }}</p>
                    <!-- <b-col class="" style="margin-top: 5px; color: #1B9AAA" cols="12" md="1">
                      <div class="set-mb">
                        <p>{{ hoursCalculate(workingHours[index]) }}hrs</p>
                        <p class="white">{{ TotalHoursCal(workingHours[index]) }}</p>
                        <p>{{new DateDiff(new Date(workingHours[index].times[innerIndex].start), new Date(workingHours[index].times[innerIndex].end)).days()}}</p>
                         {{workingHours[index].times[innerIndex].end}}
                      </div>
                    </b-col> -->
                  </b-row>
                </div>
              </b-form>
            </validation-observer>

            <!-- Save & Cancle Button -->

            <div style="height: 38px; background-color: #EBF5FB; margin-bottom: 30px">
              <span class="m-1">Total hours/week: {{ parseFloat(totalRequestedTime).toFixed(1) }}</span>
            </div>

            <div class="d-flex">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click.prevent="WorkingHoursSubmitForm"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="text-primary ml-1 shadow"
                  type="button"
                  variant="outline-white"
                  @click="closeEditeForm()"
              >
                Cancel
              </b-button>
            </div>

          </b-card-body>

        </b-card>
      </b-col>


      <b-col lg="6" md="6" v-if="openEditUnavailabilityForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="SlashIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span
                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Unavailability
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openForm(isActiveEditUnavailabilityForm)"
              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>

            <div v-for="(day,index) in unavailabilities" :key="index">
              <b-row class="mt-2" style="margin-left: 5px">
                <b-col cols="4">
                  <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="2" height="18" fill="#00C49A"/>
                  </svg>
                  <span class="ml-1"
                        style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                  {{ day.day }}
                </span>
                </b-col>
                <b-col cols="8">
                  <b-row>
                 <span v-for="(time,index) in day.times" :key="index" class="mr-2 ml-1"
                       style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                  <feather-icon class="mr-1" icon="ClockIcon"/>
                   {{ time.start + ' - ' + time.end }}</span>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6" md="6" v-if="openEditUnavailabilityForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="SlashIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span
                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Edit Unavailability
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group
                    label="Days"
                >
                </b-form-group>
              </b-col>
              <b-col class="mb-1" cols="12" md="12">

                <b-button
                    v-for="(day,index) in days"
                    :key="index"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon rounded-circle "
                    variant="white"
                    @click="toggleDaySelection(day)"
                >
                  <b-avatar
                      :class=" isUnAvailabilitiesAvailableForThisDay(day.day) ? 'text-white bg-primary ' : 'text-dark border-secondary'"
                      :style="isUnAvailabilitiesAvailableForThisDay(day.day) ? 'backgroundColor:#1B9AAA !important' : 'backgroundColor:white'"
                      :text="day.text"/>
                </b-button>


              </b-col>
            </b-row>

            <!-- Unavailable Hours -->

            <b-row class="pt-3">
              <b-col v-show="unavailabilities.length" cols="12" md="12">
                <b-form-group
                    label="Unavailable Hours"
                >
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12" md="12">
              </b-col>
            </b-row>
            <validation-observer
                ref="unavailabilityForm"
                #default="{ handleSubmit }"
            >
              <b-form
                  ref="form"
                  class="repeater-form "
              >
                <!-- Add Button -->
                <div v-for="(day,index) in unavailabilities" :key="index" class="mb-1">
                  <b-row v-for="(time,innerIndex) in day.times" :key="innerIndex">
                    <b-col v-if="innerIndex === 0" class="mt-1" cols="12 pr-0" md="2">
                      <h6>{{ day.day }}</h6>
                    </b-col>
                    <b-col v-else class="mt-1" cols="12 pr-0" md="2">
                      <h6></h6>
                    </b-col>

                    <b-col class="mb-1" cols="12" md="4">
                      <div class="set-mb">
                        <validation-provider
                            #default="{ errors }"
                            :vid="'StartTime'+index+innerIndex"
                            name="Start time"
                            rules="required"
                        >
                          <flat-pickr
                              v-model="unavailabilities[index].times[innerIndex].start"
                              :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                              class="form-control"
                              placeholder="Start time"
                          />
                          <!--                    <b-form-timepicker v-model="unavailabilities[index].times[innerIndex].start" locale="en"-->
                          <!--                                       placeholder="Start time"/>-->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                    </b-col>
                    <b-col class="mb-1" cols="12" md="4">
                      <div class="set-mb">
                        <validation-provider
                            #default="{ errors }"
                            :vid="'EndTime'+index+innerIndex"
                            name="End time"
                            rules="required"
                        >
                          <flat-pickr
                              v-model="unavailabilities[index].times[innerIndex].end"
                              :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                              class="form-control"
                              placeholder="End time"
                          />
                          <!--                    <b-form-timepicker v-model="unavailabilities[index].times[innerIndex].end" locale="en"-->
                          <!--                                       placeholder="End time"/>-->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>

                    <b-col v-if="day.times.length === innerIndex + 1" class="mb-1" cols="12" md="2">
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="btn-icon rounded-circle p-0"
                          size="20"
                          variant="outline-white"
                          @click="addSlot(unavailabilities[index].times)"
                      >
                        <feather-icon icon="PlusCircleIcon" size="17"/>
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="btn-icon rounded-circle"
                          size="20"
                          variant="outline-white"
                          @click="removeSlot(index,innerIndex)"
                      >
                        <feather-icon icon="MinusCircleIcon" size="17"/>
                      </b-button>
                    </b-col>

                    <b-col v-else class="mb-1" cols="12" md="2">
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="btn-icon rounded-circle pl-0"
                          size="20"
                          variant="outline-white"
                          @click="removeSlot(index,innerIndex)"
                      >
                        <feather-icon icon="MinusCircleIcon" size="17"/>
                      </b-button>
                    </b-col>


                  </b-row>
                </div>
              </b-form>
            </validation-observer>

            <!-- Save & Cancle Button -->

            <div class="d-flex">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click.prevent="manageUnavailabilities"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="text-primary ml-1 shadow"
                  type="button"
                  variant="outline-white"
                  @click="cancellworkingHoursChanges()"
              >
                Cancel
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProfileDownloadDropDownMenu
  from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import leaveManagementAdmin from '@/apis/modules/leave_managment_admin'
import {mapActions, mapGetters} from 'vuex'
import {
  BForm,
  BAvatar, BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab,
  BTable, BTabs,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import AddLeaveSidebar from '@/views/admin-leave/sidebars/addLeaveSidebar'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import practice from '@/apis/modules/practice'
import {heightTransition} from '@core/mixins/ui/transition'
import settings from '@/apis/modules/settings'
import {days} from '@/constants/config'
import flatPickr from 'vue-flatpickr-component'
import SettingsAPI from '@/apis/modules/settings'
import DateDiff from 'date-diff'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import moment from 'moment'
import leave from '@/apis/modules/leave'

export default {
  name: 'workingHours',
  components: {
    BForm,
    flatPickr,
    ProfileDownloadDropDownMenu,
    DropDownMenu,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    AddLeaveSidebar,
    BInputGroup,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mixins: [MomentMixin],

  data () {
    return {
      totalRequestedTime: 0,
      isActiveEditUnavailabilityForm: true,
      openEditUnavailabilityForm: false,
      isActiveEditWorkingHoursForm: true,
      openEditWorkingHoursForm: false,
      unavailabilities: [],
      addLoader: false,
      days: null,
      nextTodoId: 1,
      value: '',
      utcTime: [],
      bankHoliday: [],
      holidayLeaves: [],
      workingHours: [{
        id: 1,
        date: '',
        startTime: '',
        endTime: '',
        bMinute: 0
      }],
      timeDifference: 0,
      total: 0,
      array: []
    }
  },

  async mounted () {
    await this.getManageData()
    await this.BankHoliday()
    await this.getUserDetails()
    this.days = days
  },

  computed: {
    ...mapGetters(['currentUser']),

    isUnAvailabilitiesAvailableForThisDay () {
      return (day) => _.some(this.unavailabilities, {day: day})
    },

    isWorkingHourAvailableForThisDay () {
      return (day) => _.some(this.workingHours, {day: day})
    }
  },

  methods: {
    cancellworkingHoursChanges () {
      this.openEditUnavailabilityForm = false
    },

    async closeEditeForm () {
      await this.getUserDetails()
      this.openEditWorkingHoursForm = false
    },
    openForm (val) {
      this.openEditUnavailabilityForm = val
    },

    openWorkingHoursForm (val) {
      this.openEditWorkingHoursForm = val
    },

    async getUserDetails () {
      try {
        this.personalInfoLoading = true
        // console.log('this.$route.params.id',this.$route.params.id)
        const Response = await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data
        // console.log('this.user',this.user)
        this.unavailabilities = this.user.un_availabilities.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm')
          }))
        }))

        this.workingHours = this.user.working_hours.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm'),
            bMinute: m.break_minutes,
            difference: `${new DateDiff(new Date(m.end), new Date(m.start)).hours()}`
          }))
        }))
        this.calculateTotal(this.workingHours)
      } catch (error) {
        this.convertAndNotifyError(error)
        this.personalInfoLoading = false
      }
    },


        async manageUnavailabilities() {
     let arr = this.unavailabilities;

     this.id = "false";

     arr.forEach((id,num) => {

      let array = this.unavailabilities[num].times;
      let array_e = this.unavailabilities[num].times;


      array_e.forEach((value,index) => {

      array.forEach((val,ind) => {

        var obj = ((value.start == val.start && value.end == val.end && index == ind ) || (value.start >= val.start && value.start >= val.end && index != ind) || (value.end <= val.start && value.end <= val.end && index != ind) )

        if(!obj){
          this.id = "true";
        }

      });
      });
      });

      this.id === "false"
      this.submitForm(this.id)
      console.log("id "+this.id)

    },

    async submitForm (id) {
      this.addLoader = true
      if(id === "false"){
      this.$refs.unavailabilityForm.validate().then(async success => {
        if (success) {
          try {
            //convert to utc
            this.utcTime = this.unavailabilities.map((x) => ({
              day_number: this.getWeekDayNumberViaDate(x),
              times: x.times.map((m) => ({
                start: this.getUTCConvertedTime(x.day_number, m.start),
                end: this.getUTCConvertedTime(x.day_number, m.end)
              }))
            }))

            if (this.utcTime.length === 0) {
              this.showErrorMessage('Please add at least one new  Unavailability')
            } else {
              let data = await settings.updateUnAvailabilities({un_availabilities: this.utcTime}, this.$route.params.id)
              this.showSuccessMessage('Unavailability updated successfully')
              this.openEditUnavailabilityForm = false
            }
            this.addLoader = false

          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      }
      else{
        this.showErrorMessage("Same unavailability time is detected");
      }
      this.addLoader = false

    },
    toggleDaySelection (selectedDay) {
      const index = this.unavailabilities.findIndex(obj => obj.day === selectedDay.day)
      if (index >= 0) {
        this.unavailabilities.splice(index, 1)
      } else {
        this.unavailabilities.push({
          day_number: selectedDay.day_number,
          day: selectedDay.day,
          times: [{}]
        })
      }
    },
    async removeSlot (index, innerIndex) {
      await this.unavailabilities[index].times.splice(innerIndex, 1)
      if (this.unavailabilities[index].times.length === 0) this.unavailabilities.splice(index, 1)
    },
    addSlot (slotToBeAddTime) {
      slotToBeAddTime.push({})
    },
    removeItem (index) {
      this.unavailabilities.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    async WorkingHoursSubmitForm () {
      this.addLoader = true
      this.$refs.workingHoursForm.validate().then(async success => {
        if (success) {
          try {
            //convert to utc
            this.utcTime = this.workingHours.map((x) => ({
              day_number: this.getWeekDayNumberViaDate(x),
              times: x.times.map((m) => ({
                start: this.getUTCConvertedTime(x.day_number, m.start),
                end: this.getUTCConvertedTime(x.day_number, m.end),
                bMinutes: m.bMinute ? m.bMinute : 0
              }))
            }))

            if (this.utcTime.length === 0) {
              this.showErrorMessage('Please add at least one new  Working hours')
            } else {
              await leaveManagementAdmin.cancelAllBankHolidayLeave(this.$route.params.id);
              await this.checkHaveBankHoliday(this.utcTime)
              await this.addLeave()

              await SettingsAPI.updateWorkingHours({working_hours: this.utcTime}, this.$route.params.id)
              this.showSuccessMessage('Working hours updated successfully')

              this.openEditWorkingHoursForm = false
              await this.getUserDetails()
            }

            this.addLoader = false
          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      this.addLoader = false

    },
    hoursCalculate (workingHours) {
      if (workingHours.times[0].end && workingHours.times[0].start) {
        let start = (workingHours.times[0].start).split(':')
        let end = (workingHours.times[0].end).split(':')
        let startDate = new Date()
        let endDate = new Date()

        startDate.setHours(start[0], start[1])
        endDate.setHours(end[0], end[1])

        let timeDiffHours = new DateDiff(new Date(endDate), new Date(startDate)).hours()
        if (workingHours.times[0].bMinute) {
          timeDiffHours = (((timeDiffHours * 60) - (workingHours.times[0].bMinute * 1)) / 60)
        }
        timeDiffHours = parseFloat(timeDiffHours).toFixed(1)
        return timeDiffHours
      }
    },

    TotalHoursCal () {
      let totalCalc = 0
      for (let workingHour of this.workingHours) {
        if (workingHour.times[0].end && workingHour.times[0].start) {
          let start = (workingHour.times[0].start).split(':')
          let end = (workingHour.times[0].end).split(':')
          let startDate = new Date()
          let endDate = new Date()
          let bMinutes = new Date()

          startDate.setHours(start[0], start[1])
          endDate.setHours(end[0], end[1])

          let timeDiffHours = new DateDiff(new Date(endDate), new Date(startDate), new Date(bMinutes)).hours()

          if (workingHour.times[0].bMinute) {
            timeDiffHours = (((timeDiffHours * 60) - (workingHour.times[0].bMinute * 1)) / 60)
          }
          totalCalc = totalCalc + timeDiffHours
        }
        this.totalRequestedTime = totalCalc
      }
    },

    calculateTotal (val) {
      this.total = 0
      for (let i = 0; i < val.length; i++) {

        for (let a = 0; a < val[a].times.length; a++) {
          this.total = Number(this.total) + (((Number(val[i].times[a].difference * 60) - val[i].times[a].bMinute) / 60))
        }
      }
      return this.total
    },

    WorkingHoursToggleDaySelection (selectedDay) {
      const index = this.workingHours.findIndex(obj => obj.day === selectedDay.day)
      if (index >= 0) {
        this.workingHours.splice(index, 1)
      } else {
        this.workingHours.push({
          day_number: selectedDay.day_number,
          day: selectedDay.day,
          times: [{}]
        })
      }
    },

    async getManageData () {
      const ManageLeaveResponse = await AdminLeaveAPI.manageLeaveDetails()
    },

    async BankHoliday () {
      let date = new Date()
      const PublicHolidayResponse = await AdminLeaveAPI.getUKPublicHolidays()

      //map data to array
      let array = PublicHolidayResponse.data.map((e) => ({
        title: e.title,
        date: e.date,
        notes: e.notes,
        day_number: new Date(e.date).getDay()
      }))

      //get upcoming bank holidays
      this.bankHoliday = array.filter((e) => {
        return new Date(e.date) >= date
      })
    },


    //This is used check public holiday with working time
    checkHaveBankHoliday (data) {
      this.holidayLeaves = []
      data.forEach((e) => {
        this.bankHoliday.forEach((x) => {
          if (e.day_number === x.day_number) {
            let obj = {
              times: e.times,
              date: x.date,
              bank_holiday_title: x.title,
              day_number: x.day_number,
              notes: x.notes

            }
            this.holidayLeaves.push(obj)
          }
        })
      })
    },

    async addLeave () {
      try {
        if (this.holidayLeaves.length > 0) {
          let response = []
          let user_ids = []
          user_ids.push(this.$route.params.id)

          let payload = {}
          let leaves = []

          leaves = this.holidayLeaves.map((x) => ({
                start: this.momentFormat(moment(x.date + ` ` + x.times[0].start), 'YYYY-MM-DD HH:mm:ss'),
                end: this.momentFormat(moment(x.date + ` ` + x.times[0].end), 'YYYY-MM-DD HH:mm:ss'),
                break_minutes: x.times[0].bMinutes,
                bank_holiday_title: x.bank_holiday_title
              })
          )

          payload.leaveItems = leaves
          payload.user_id = user_ids
          payload.leave_reason = 7
          payload.sickness_type = ''
          payload.is_day = false

          response = await leave.requestAdminAndStaffLeaves(payload)


          if (response.data.success) {
          } else {
            if (response.data.conflict) {
              let name = response.data.first_name + ' ' + response.data.last_name
              let date = this.momentFormat(response.data.start, 'DD-MMM-YY')
              let startTime = this.momentFormat(response.data.start, 'HH:mm')
              let endTime = this.momentFormat(response.data.end, 'HH:mm')
              let lockedDay = response.data.locked_day

              this.showLeaveConflictErrorMessage(name, date, startTime, endTime, lockedDay)
            }
          }
        }
      } catch (error) {
        this.convertAndNotifyError(error)
      }

    }
  }
}
</script>

<style scoped>
.white {
  color: rgb(255, 255, 255);
}

.mins {
  margin-top: 5px;
  margin-left: -12px;
}

.start-time {
  margin-left: -15px;
}
</style>
