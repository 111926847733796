import { render, staticRenderFns } from "./UploadJobSpecification.vue?vue&type=template&id=d273f54c&scoped=true&"
import script from "./UploadJobSpecification.vue?vue&type=script&lang=js&"
export * from "./UploadJobSpecification.vue?vue&type=script&lang=js&"
import style1 from "./UploadJobSpecification.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d273f54c",
  null
  
)

export default component.exports