<template>
  <div>
    <b-row class="match-height">
      <b-col lg="5" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="MapPinIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span
                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Locations
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <!-- <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"

              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button> -->
            </div>
          </b-card-header>

          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50"
                style="max-height: 400px"
            >
              <ul class="list-group list-group-flush">
                <li
                    v-for="location in connectedLocations"
                    class="list-group-item font-black"
                >
                  <span
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                    {{ location.practice.name }}
                  </span>
                  <br/>
                  <span class=""
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: rgba(23, 24, 34, 0.6);">
                    {{ location.practice.address }},
                    {{ location.practice.city }},
                    {{ location.practice.postal_code }}
                  </span
                  >
                </li>
              </ul>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="7" md="6" v-if="this.openEditEmployInfo === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileTextIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span
                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Employment Info
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openEditEmploymentInfoForm(isActiveEditEmployInfo)"
              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">

              <b-col lg="6" md="6">
                <b-row>
                  <b-col lg="6" md="6">
                    <span
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                      Date Hired
                    </span>
                    <h5 class="mb-0"
                        style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                      {{
                        user.practice_user_employment_information ? ChangeFormateDate(user.practice_user_employment_information.date_hired) : '-'
                      }}
                    </h5>
                  </b-col>

                  <b-col lg="6" md="6">
                    <span
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                      Employment Type
                    </span>
                    <h5 class="mb-0"
                        style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                      {{
                        user.practice_user_employment_information ? user.practice_user_employment_information.employment_type : '-'
                      }}
                    </h5>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col lg="6" md="6">
                    <span
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                      Salary
                    </span>
                    <h5 class="mb-0"
                        style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                      {{
                        user.practice_user_employment_information ? '£ ' + new Intl.NumberFormat().format(user.practice_user_employment_information.salary) : '-'
                      }}
                    </h5>
                  </b-col>

                  <b-col lg="6" md="6">
                    <span
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                      DBS Status
                    </span>
                    <h5 class="mb-0"
                        style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                      {{
                        user.practice_user_employment_information ? user.practice_user_employment_information.dbs_status : '-'
                      }}
                    </h5>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col lg="12" md="6">
                    <span
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                      DBS Renewal Date
                    </span>
                    <h5 class=""
                        style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                      {{
                        user.practice_user_employment_information ? ChangeFormateDate(user.practice_user_employment_information.dbs_renewal_date) : '-'
                      }}
                    </h5>
                  </b-col>


                </b-row>
              </b-col>

              <b-col lg="6" md="6">
                <div>
                <span class=""
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                  Employment Contract
                </span>
                </div>
                <div style="margin-top: 5px;" class="ml-0 mb-2" v-if="user.practice_user_employment_information && user.practice_user_employment_information.practiceusercontactdocument">
                  {{ user.practice_user_employment_information.practiceusercontactdocument.name }}
                </div>
                
                <h5 v-else class="mb-0"
                    style="margin-top: 20px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px">

                  <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>
                  <span class="ml-1"
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">
                    Upload Employment Contract
                  </span>
                </h5>

                <div class="mt-3">
                <span
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                 Job Specification
                </span>
                </div>
                <div style="margin-top: 5px;" class="ml-0" v-if="user.practice_user_employment_information && user.practice_user_employment_information.practiceuserjob">
                  {{ user.practice_user_employment_information.practiceuserjob.name }}
                </div>
                <h5 v-else class="mb-0"
                    style="margin-top: 20px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px">
                  <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>
                  <span class="ml-1"
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">
                    Upload Job Specification
                  </span>
                </h5>
              </b-col>


            </b-row>

          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="7" md="6" v-if="this.openEditEmployInfo === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileTextIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span
                    style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Employment Info
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">

              <b-col lg="6" md="6">
                <validation-observer ref="EditPersonalInfoForm">
                  <b-form @submit.prevent>
                    <div class="mr-2 ml-2">
                      <b-row>
                        <b-col cols="12" md="12">
                          <b-form-group
                              label="Date hired"
                              label-for="Date hired"
                          >
                            <validation-provider
                                #default="{ errors }"
                                name="Date hired"
                                rules=""
                            >
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="CalendarIcon"/>
                                </b-input-group-prepend>
                                <flat-pickr
                                    v-model="form.date_hired"
                                    :config="{ enableTime: false, noCalendar: false, dateFormat: 'd-m-Y'}"
                                    class="form-control"
                                    placeholder="Select hired date"


                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Employment type -->
                        <b-col cols="12" md="12">
                          <b-form-group
                              label="Employment type"
                              label-for="h-Preferred_pronouns"
                          >
                            <validation-provider
                                #default="{ errors }"
                                name="Employment type"
                                rules="required"
                            >
                              <b-input-group class="input-group-merge">

                                <v-select
                                    class="w-100"
                                    v-model="form.employment_type"
                                    :reduce="title => title.value"
                                    :options="[{title:'Full time', value:'Full time'},
                                         {title:'Part time', value:'Part time'},
                                         {title:'Casual', value:'Casual'},
                                         {title:'AARS', value:'AARS'}
                                         ]"

                                    label="title"
                                    placeholder="Employment type"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12" md="12">
                          <div class="">
                            <b-form-group
                                class="input-group-merge"
                                label="Salary"
                                label-for="home-Salary"
                            >

                              <validation-provider
                                  #default="{ errors }"
                                  name="Salary"
                                  rules=""
                              >
                                <b-input-group class="input-group-merge">

                                  <b-input-group-prepend is-text>
                                    £
                                  </b-input-group-prepend>
                                  <b-form-input
                                      id="home-address"
                                      v-model="form.salary"
                                      placeholder="Enter Salary"
                                      type="number"


                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                        </b-col>
                        <b-col cols="12" md="12">
                          <b-form-group
                              label="DBS status"
                              label-for="h-DBS status"
                          >
                            <validation-provider
                                #default="{ errors }"
                                name="DBS status"
                                rules="required"
                            >
                              <b-input-group class="input-group-merge">

                                <v-select
                                    class="w-100"
                                    v-model="form.dbs_status"
                                    :reduce="title => title.value"
                                    :options="[{title:'Not started', value:'Not started'},
                                         {title:'In progress', value:'In progress'},
                                         {title:'Completed', value:'Completed'},
                                         ]"
                                    label="title"
                                    placeholder="Select DBS status"


                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="12">
                          <b-form-group
                              label="DBS renewal Date "
                              label-for="h-DBS renewal Date"
                          >
                            <validation-provider
                                #default="{ errors }"
                                name="DBS renewal Date"
                                rules="required"
                            >
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="CalendarIcon"/>
                                </b-input-group-prepend>
                                <flat-pickr
                                    v-model="form.dbs_renewal_date"
                                    :config="{ enableTime: false, noCalendar: false, dateFormat: 'd-m-Y'}"
                                    class="form-control"
                                    placeholder="Select DBS renewal Date "

                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>


                        <b-col md="12" class="mt-2">
                          <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="mr-1"
                              type="submit"
                              variant="primary"
                              @click="updateEmploymentInfo()"
                          >
                            Save
                          </b-button>
                          <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              class="text-primary ml-1 shadow"
                              type="button"
                              variant="outline-white"
                              @click="openEditEmployInfo = false"
                          >
        <span class="text-primary">
          Cancel
        </span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form>
                </validation-observer>

              </b-col>

              <b-col lg="6" md="6">

                <span class="mb-1"
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                  Employment Contract
                </span>

                
                <a href="#" @click="openUploadEmploymentContactPopup()">
                  <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>
                  <span class="ml-1"
                        style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">
                    Upload Employment Contract
                  </span>
                </a>
                <div class="ml-3" v-if="uploadedContactDocument.name">
                  {{ uploadedContactDocument.name }}
                </div>


                <span class="mt-3 mb-1"
                      style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">
                 Job Specification
                </span>
                
               <a href="#" @click="openUploadJobPopup()">
                    <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>
                    <span class="ml-1"
                          style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">
                    Upload Job Specification
                  </span>
                  </a>
                  <div class="ml-3" v-if="uploadedJobSpecificationDocument.name">
                  {{ uploadedJobSpecificationDocument.name }}
                </div>
              </b-col>


            </b-row>

          </b-card-body>
        </b-card>
      </b-col>


    </b-row>
    <UploadCertificate ref="uploadEmploymentContact" @getContactDocument="getContactDocument"/>

    <UploadJobSpecification ref="uploadJobSpecification" @getJobSpecificationDocument="getJobSpecificationDocument"/>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BForm,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab, BTabs, BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import AddLeaveSidebar from '@/views/admin-leave/sidebars/addLeaveSidebar'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import UserNoteAPI from '@/apis/modules/user_notes'
import practice from '@/apis/modules/practice'
import EditProfileSidebar from '@/views/staff-bank/sidebar/EditProfile'
import AddNoteSidebar from '@/views/staff-bank/staff-profile/sidebars/addNoteSidebar'
import EditNoteSidebar from '@/views/staff-bank/staff-profile/sidebars/editNoteSidebar'
import RequestLeave from '@/views/staff-bank/staff-profile/sidebars/requestLeave'
import ProfileDownloadDropDownMenu
  from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import staffBank from '@/apis/modules/staffBank'
import UploadCertificate from '@/views/staff-bank/staff-profile/includes/popup/UploadEmploymentContact.vue'
import UploadJobSpecification from '@/views/staff-bank/staff-profile/includes/popup/UploadJobSpecification.vue'


export default {
  name: 'employmentInfo',
  components: {
    flatPickr,
    EditProfileSidebar,
    AddNoteSidebar,
    EditNoteSidebar,
    RequestLeave,
    ProfileDownloadDropDownMenu,
    DropDownMenu,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BForm,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    AddLeaveSidebar,
    BInputGroup,
    BFormGroup,
    UploadCertificate,
    UploadJobSpecification
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mixins: [MomentMixin],

  data () {
    return {
      isActiveEditEmployInfo: true,
      openEditEmployInfo: false,
      connectedLocations: [],
      user: '',
      loading: false,
      isHaveProfileImage: false,
      userImage: null,
      changeImageButton: true,
      uploadImage: [],
      userData: {},

      form: {
        date_hired: '',
        employment_type: '',
        salary: '',
        dbs_status: '',
        dbs_renewal_date: '',
        contact_document_id: '',
        job_document_id: ''
      },
      EmploymentInfo: {},
      uploadedContactDocument: {},
      uploadedJobSpecificationDocument: {}
    }
  },

  mounted () {
    this.getUserConnectedLocations()
    this.getUserDetails()
  },

  methods: {
    openEditEmploymentInfoForm (val) {
      this.openEditEmployInfo = val
    },

    openUploadEmploymentContactPopup () {
      this.$refs.uploadEmploymentContact.open()
    },

    openUploadJobPopup () {
      this.$refs.uploadJobSpecification.open()
    },

    async getContactDocument (respond) {
      console.log('ava')
      if (respond) {
        console.log(respond)
        this.uploadedContactDocument = respond
        this.form.contact_document_id = this.uploadedContactDocument.id
      }
    },

    async getJobSpecificationDocument (respond) {
      console.log('ava')
      if (respond) {
        console.log(respond)
        this.uploadedJobSpecificationDocument = respond
        this.form.job_document_id = this.uploadedJobSpecificationDocument.id
      }
    },

    async updateEmploymentInfo () {
      try {
        let payload={}
        if(this.form.contact_document_id && this.form.job_document_id){
           payload = {
            date_hired: moment(this.form.date_hired, 'DD.MM.YYYY').utc().format('YYYY-MM-DD'),
            employment_type: this.form.employment_type,
            salary: this.form.salary,
            dbs_status: this.form.dbs_status,
            contact_document_id: this.form.contact_document_id,
             job_document_id: this.form.job_document_id,
            dbs_renewal_date: moment(this.form.dbs_renewal_date, 'DD.MM.YYYY').utc().format('YYYY-MM-DD')
          }
        }else if(this.form.contact_document_id){
          payload = {
            date_hired: moment(this.form.date_hired, 'DD.MM.YYYY').utc().format('YYYY-MM-DD'),
            employment_type: this.form.employment_type,
            salary: this.form.salary,
            dbs_status: this.form.dbs_status,
            contact_document_id: this.form.contact_document_id,
            dbs_renewal_date: moment(this.form.dbs_renewal_date, 'DD.MM.YYYY').utc().format('YYYY-MM-DD')
          }
        }else if(this.form.job_document_id){
          payload = {
            date_hired: moment(this.form.date_hired, 'DD.MM.YYYY').utc().format('YYYY-MM-DD'),
            employment_type: this.form.employment_type,
            salary: this.form.salary,
            dbs_status: this.form.dbs_status,
            job_document_id: this.form.job_document_id,
            dbs_renewal_date: moment(this.form.dbs_renewal_date, 'DD.MM.YYYY').utc().format('YYYY-MM-DD')
          }
        }else {
          payload = {
            date_hired: moment(this.form.date_hired, 'DD.MM.YYYY').utc().format('YYYY-MM-DD'),
            employment_type: this.form.employment_type,
            salary: this.form.salary,
            dbs_status: this.form.dbs_status,
            dbs_renewal_date: moment(this.form.dbs_renewal_date, 'DD.MM.YYYY').utc().format('YYYY-MM-DD')
          }
        }

        const response = await staffBank.updateEmploymentInformation(payload, this.$route.params.id)
        if (response.data.success) {
          this.showSuccessMessage('Employment Info updated successfully')
          await this.getUserDetails()
          this.openEditEmployInfo = false
        }

      } catch (error) {
        this.convertAndNotifyError(error)
      }

    },

    ChangeFormateDate (oldDate) {
      return oldDate.toString().split('-').reverse().join('-')
    },
    async getUserConnectedLocations () {
      try {
        this.leaveLoading = true
        const Response = await practice.getConnectedLocations(
            this.currentPage,
            49,
            this.$route.params.id
        )
        this.connectedLocations = Response.data.data
        this.leaveLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.leaveLoading = false
      }
    },

    async getUserDetails () {
      try {
        const Response = await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data
        // console.log('this.user',this.user)
        this.uploadedJobSpecificationDocument = this.user.practice_user_employment_information && this.user.practice_user_employment_information.practiceuserjob ? this.user.practice_user_employment_information.practiceuserjob:''
        this.uploadedContactDocument = this.user.practice_user_employment_information && this.user.practice_user_employment_information.practiceusercontactdocument ? this.user.practice_user_employment_information.practiceusercontactdocument:''
        this.form.job_document_id = this.user.practice_user_employment_information && this.user.practice_user_employment_information.practiceusercontactdocument ? this.user.practice_user_employment_information.practiceusercontactdocument.id:''
        this.form.contact_document_id = this.user.practice_user_employment_information && this.user.practice_user_employment_information.practiceuserjob ? this.user.practice_user_employment_information.practiceuserjob.id:''
        this.form.date_hired = this.user.practice_user_employment_information ? this.momentFormat(this.user.practice_user_employment_information.date_hired, 'DD/MM/YYYY') : ''
        this.form.employment_type = this.user.practice_user_employment_information ? this.user.practice_user_employment_information.employment_type : ''
        this.form.salary = this.user.practice_user_employment_information ? this.user.practice_user_employment_information.salary : ''
        this.form.dbs_status = this.user.practice_user_employment_information ? this.user.practice_user_employment_information.dbs_status : ''
        this.form.dbs_renewal_date = this.user.practice_user_employment_information ? this.momentFormat(this.user.practice_user_employment_information.dbs_renewal_date, 'DD/MM/YYYY') : ''

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    }
  }
}
</script>

<style scoped>

</style>