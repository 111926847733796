<template>
  <div>

    <b-card no-body>
      <b-row>

        <!-- User Avatar -->

        <b-col cols="12"  xl="1">

          <b-row>
            <b-col cols="12" class="d-flex justify-content-start" >
              <b-button
                  @click="$router.back()"
                  class="shadow-sm font-weight-bold  align-self-baseline float-left"
                  variant="white">
                <feather-icon icon="ChevronLeftIcon" size="20"/>
              </b-button>
              <div style="width: 250px">
                <b-avatar :src="getUserImage(user.id, true)"
                          class="mt-1 mr-1 badge-minimal"
                          badge-variant="success"
                          variant="light-primary"
                          size="110px"
                          style="padding:7px"
                />
              </div>

            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12"  xl="11" >
          <b-row class="ml-5">

            <!-- Name & Alert -->

            <b-col cols="12"  xl="5">
              <div class="d-flex align-items-center " v-if="user">

                <div class="d-md-flex flex-row text-center mr-1 ml-1">

                  <h3 class="mb-0 font-weight-700 mt-2" id="user_name">{{user.first_name +' '+ user.last_name}} </h3>
                </div>

                <div class="mt-1">
                  <!--                            <b-alert   variant="primary" show>-->
                  <!--                                <small class="text-dark font-weight-700 mx-1 " >L-->
                  <!--                                    </small>-->
                  <!--                            </b-alert>-->

                </div>

                <div class="mt-2" style="padding-left: 6px">
<!--                  <b-alert   variant="primary" show>-->
<!--                    <small class="text-dark font-weight-700 mx-1 " id="job_role">{{user.job_roles||user.job_roles[0]?user.job_roles[0].name:''}}-->
<!--                    </small>-->
<!--                  </b-alert>-->

                </div>
              </div>
            </b-col>


            <b-col cols="12"  xl="7" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">
              <b-row >


                <!-- Hours worked / Earned  -->

                <b-col cols="12" md="4" class="d-flex justify-content-center justify-content-xl-end">

                </b-col>

                <!-- Send Message & Download Button -->

                <b-col cols="12" md="8" class=" pr-3 pl-3 pl-md-0  my-2 my-md-0">
                  <b-row>
                    <b-col cols="12" md="7" class=" pr-3 pl-3 pl-md-0  my-md-0 ">


                    </b-col>
                    <b-col cols="12" md="5" class=" pl-md-0  my-md-0">

                      <div class="d-flex justify-content-end ">
                        <b-avatar
                            variant="light-primary"
                            rounded
                        >
                          <feather-icon
                              icon="ClockIcon"
                              size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h4 class="mb-0 font-weight-bolder">
                            {{(calculateHoursWorked(user.shifts)).toFixed(1)}}
                          </h4>
                          <small>Hours Worked</small>
                        </div>
                      </div>
<!--                      <b-button-->
<!--                          class="btn-block "-->
<!--                          variant="primary"-->
<!--                          @click=""-->
<!--                      >-->
<!--                        <feather-icon-->
<!--                            icon="SendIcon"-->
<!--                            class="mr-50"-->
<!--                        />-->
<!--                        <span class="align-middle">Send Message</span>-->
<!--                      </b-button>-->
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-col>
          </b-row>

          <b-col cols="3" class="d-flex">
          <div class="ml-5" style="padding-left: 16px;">
            <b-alert   variant="primary" show>
              <small class="text-dark font-weight-700 mx-1" id="job_role1">{{user.job_roles||user.job_roles[0]?user.job_roles[0].name:''}}
              </small>
            </b-alert>

          </div>
          </b-col>



        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12" class="">
          <b-tabs lazy class="ml-5" v-model="tabIndex">
            <b-tab title="Personal Info">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0 mr-5">
                <personalInfo @updateDetails="updatedDetails" />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Employment Info">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                <employmentInfo />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Leave">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                <leave />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Working Hours">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                <workingHours />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Documents & Training">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                <documentsAndTraining />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Shifts">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                <shifts />
                </b-card>
              </div>
            </b-tab>

            <!--          <b-tab title="Timesheets">-->
            <!--            <div class="alert-body"><timeSheets /></div>-->
            <!--          </b-tab>-->

            <b-tab title="Settings">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                <settings />
                </b-card>
              </div>
            </b-tab>

          </b-tabs>
        </b-col>
      </b-row>

    </b-card>



  </div>
</template>

<script>
// import EditProfileSidebar from '@/views/staff-bank/sidebar/EditProfile'
import Available from '@/views/staff-bank/staff-profile/includes/availability-table'
import practice from '@/apis/modules/practice'
import personalInfo from '@/views/staff-bank/staff-profile/includes/personalInfo'
import employmentInfo from '@/views/staff-bank/staff-profile/includes/employmentInfo'
import leave from '@/views/staff-bank/staff-profile/includes/leave'
import workingHours from '@/views/staff-bank/staff-profile/includes/workingHours'
import documentsAndTraining from '@/views/staff-bank/staff-profile/includes/documentsAndTraining'
import shifts from '@/views/staff-bank/staff-profile/includes/shifts'
// import timeSheets from '@/views/staff-bank/staff-profile/includes/timeSheets'
import settings from '@/views/staff-bank/staff-profile/includes/settings'

import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BAlert, BTabs, BTab, VBTooltip} from 'bootstrap-vue'

export default {
  name: 'profile-image-bar',
  components: {
    settings,
    // timeSheets,
    shifts,
    documentsAndTraining,
    workingHours,
    leave,
    employmentInfo,
    personalInfo,
    VBTooltip,
    Available,
    // EditProfileSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
     BAlert,
     BTabs,
     BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tabIndex:0,
      isEditProfileSidebarActive: false,
      user:'',
      mediaData: [
        {avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM'},
        {avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City'},
      ],
      avatars: [
        {avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper'},
      ],
      professionalInfoItSystems:{}

    }
  },
   methods: {

    ChangeFormateDate(oldDate){
   return oldDate.toString().split("-").reverse().join("-");
    },

     updatedDetails() {
       this.getUserDetails()

     },

     async updateProfessionalInfo () {
       await this.getUserDetails()
     },

     updatedRole(data) {
     this.user.job_roles[0].name = data
     },
    toggleSideBars() {
      this.isEditProfileSidebarActive = !this.isEditProfileSidebarActive
    },
    async  getUserDetails(){
      try {
        const Response =  await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data
        this.professionalInfoItSystems = this.user.multiple_it_systems.map((x) => ({
          // value: x.it_system[0].id,
          label: x.it_system[0].name,

        }))
      }catch (error){
        this.convertAndNotifyError(error)
      }
     }
},
  computed (){
    if (this.$route.params.tab_number){
      this.tabIndex = this.$route.params.tab_number
    }
  },
  async mounted () {
    if (this.$route.query.tab_number){
      this.tabIndex = this.$route.query.tab_number
    }
    const params = this.$route.params
    const query = this.$route.query

   await this.getUserDetails()


      if (params && Boolean(parseInt(params.is_dummy))) {
        document.getElementById('user_name').innerHTML = query.name
        document.getElementById('job_role').innerHTML = query.job_role

      }

  },
  watch:{
    isEditProfileSidebarActive(val){
      this.$refs.availability.getMyUnAvailabilities();
    }
  }
}
</script>

<style scoped>
button{
  height: 38px;
}
</style>
