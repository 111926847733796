<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="6">
        <b-overlay :show="loadDocument" rounded="sm">
          <b-card no-body>
            <b-card-header class="border-bottom pb-1">
              <div class="d-flex flex-row">
                <b-avatar rounded variant="light-primary">
                  <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="ml-1" style="padding-top: 8px">
                  <span
                    style="
                      font-family: Mulish;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 20px;
                      line-height: 25px;
                      color: #171822;
                    "
                  >
                    Documents
                  </span>
                </div>
              </div>
              <div class="ml-auto">
                <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openDocumentSideBar()"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="'Add Documents'"
                    icon="PlusIcon"
                    size="20"
                    class="text-primary"
                  />
                </b-button>
              </div>
            </b-card-header>

            <b-card-body>
              <div v-if="getDocuments.length > 0">
                <div v-for="(item, index) in getDocuments" :key="index">
                  <!-- CV  -->
                  <div>
                    <div
                      style="
                        height: 40px;
                        background-color: #ebf5fb;
                        margin-top: 20px;
                      "
                    >
                      <span
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 12px;
                          line-height: 15px;
                          text-transform: uppercase;
                          color: #171822;
                        "
                      >
                        <b-row>
                          <span
                            class="ml-2 mt-1"
                            style="
                              padding-bottom: 5px;
                              font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 12px;
                              line-height: 15px;
                              text-transform: uppercase;
                              color: #171822;
                            "
                          >
                            {{ item.type }}</span
                          >
                        </b-row>
                      </span>
                    </div>

                    <b-row>
                    <b-media no-body>
                        <span class="ml-2 mt-2">
                          <b-img
                            v-if="item.file_type === 'application/pdf'"
                            :src="
                              require('@/assets/images/icons/file-icons/pdf.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                            :src="
                              require('@/assets/images/icons/file-icons/docx.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                            :src="
                              require('@/assets/images/icons/file-icons/excel.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'application/msword'"
                            :src="
                              require('@/assets/images/icons/file-icons/excel.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'application/vnd.ms-excel'"
                            :src="
                              require('@/assets/images/icons/file-icons/excel.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'image/png'"
                            :src="
                              require('@/assets/images/icons/file-icons/png.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'image/jpeg'"
                            :src="
                              require('@/assets/images/icons/file-icons/jpg.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                          <b-img
                            v-if="item.file_type === 'application/xls'"
                            :src="
                              require('@/assets/images/icons/file-icons/xls.png')
                            "
                            alt="browser img"
                            class="mr-1"
                            height="30"
                          />
                        </span>
                      </b-media>
                      <span
                        class="mt-1"
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 21px;
                          color: #171822;
                        "
                      >
                        {{ item.name }} <br />
                        <span
                          style="
                            font-family: Mulish;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #949494;
                          "
                        >
                          Uploaded by {{ item.updated_by }}
                          <!--                          {{ item.user.first_name }} {{ item.user.last_name }}-->
                        </span>
                      </span>
                      <span
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mt-1 mr-1"
                      >
                        <b-button
                          class="btn-icon font-weight-bold"
                          variant="white"
                          @click="downloadDocumentFile(item)"
                        >
                          <feather-icon
                            v-b-tooltip.hover.top="'Download'"
                            icon="DownloadIcon"
                            size="20"
                            class="text-secondary"
                          />
                        </b-button>

                        <b-button
                          class="btn-icon font-weight-bold"
                          variant="white"
                          @click="deleteFile(item)"
                        >
                          <feather-icon
                            v-b-tooltip.hover.top="'Delete'"
                            icon="TrashIcon"
                            size="20"
                            class="text-secondary"
                          />
                        </b-button>
                      </span>
                    </b-row>
                  </div>
                </div>
              </div>

              <div v-else class="text-center mt-4">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z"
                    fill="#CCE6F4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z"
                    fill="white"
                  />
                  <path
                    d="M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z"
                    fill="black"
                  />
                  <path
                    d="M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z"
                    fill="black"
                  />
                  <path
                    d="M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z"
                    fill="black"
                  />
                  <path
                    d="M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z"
                    fill="black"
                  />
                  <path
                    d="M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z"
                    fill="#1B9AAA"
                  />
                  <path
                    d="M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z"
                    fill="#1B9AAA"
                  />
                  <path
                    d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                    fill="#1B9AAA"
                    stroke="#1B9AAA"
                    stroke-width="0.5"
                  />
                  <mask
                    id="mask0_1872_13634"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="14"
                    y="11"
                    width="6"
                    height="5"
                  >
                    <path
                      d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1872_13634)">
                    <path
                      d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                      fill="#1B9AAA"
                    />
                    <mask
                      id="mask1_1872_13634"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="14"
                      y="11"
                      width="6"
                      height="5"
                    >
                      <path
                        d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask1_1872_13634)"></g>
                  </g>
                </svg>

                <h5 class="mt-2">No Documents</h5>
                <div style="height: 100px"></div>
              </div>
            </b-card-body>
          </b-card>

          <b-modal
            id="modal-center"
            v-model="showFileUploadModal"
            centered
            hide-footer
            no-close-on-backdrop
            size="lg"
            title="Upload Document"
          >
            <b-overlay :show="modalLoading" rounded="sm">
              <b-row class="mx-1">
                <b-col class="mt-2" cols="12">
                  <div class="m-auto" style="width: 100px; height: 100px">
                    <svg
                      id="Layer_1"
                      style="enable-background: new 0 0 512 512"
                      version="1.1"
                      viewBox="0 0 512 512"
                      x="0px"
                      xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      y="0px"
                    >
                      <rect
                        height="512"
                        style="fill: #32bea6"
                        width="512"
                        y="0"
                      />
                      <g>
                        <polygon
                          points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	"
                          style="fill: #ffffff"
                        />
                        <polygon
                          points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	"
                          style="fill: #ffffff"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </div>
                </b-col>
                <b-col class="mt-3" cols="12">
                  <b-overlay :show="buttonLoading" rounded="sm">
                    <v-select
                      v-model="selected_file_type_id"
                      :options="fileTypeOption"
                      :reduce="(fileTypeOption) => fileTypeOption.id"
                      label="name"
                      placeholder="Select Document Type"
                      value="id"
                      class="mb-2"
                    />

                    <b-form-file
                      ref="image_ref"
                      v-model="uploadedFile"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here..."
                    />
                  </b-overlay>
                </b-col>
                <b-col class="mt-5" cols="12">
                  <div class="float-right">
                    <b-button
                      :disabled="isActive"
                      class="mr-2"
                      variant="primary"
                      @click="uploadFile()"
                    >
                      Upload
                    </b-button>

                    <b-button
                      variant="warning"
                      @click="(isActive = true), (showFileUploadModal = false)"
                      >Cancel
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-modal>
        </b-overlay>
      </b-col>

      <b-col lg="6" md="6">
        <b-overlay :show="loadingModel" rounded="sm">
          <b-card no-body>
            <b-card-header class="border-bottom pb-1">
              <div class="d-flex flex-row">
                <b-avatar rounded variant="light-primary">
                  <!-- icon="BookOpenIcon" -->
                  <feather-icon
                    class="text-dark font-weight-700"
                    icon="SlashIcon" 
                    size="18"
                  />
                  
                </b-avatar>
                <div class="ml-1" style="padding-top: 8px">
                  <span
                    style="
                      font-family: Mulish;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 20px;
                      line-height: 25px;
                      color: #171822;
                    "
                  >
                    Mandatory Training
                  </span>
                </div>
              </div>
              <div class="ml-auto">
                <!-- <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openAddMandatoryTrainingPopup()"
                > -->

                <b-button
                class="btn-icon bg-white shadow-sm font-weight-bold"
                variant="white"
                @click="toggleAddMandatorySideBar()"
              > 



                  <feather-icon
                    v-b-tooltip.hover.top="'Add'"
                    icon="PlusIcon"
                    size="20"
                    class="text-primary"
                  />
                </b-button>
              </div>
            </b-card-header>

            <b-card-body>
              <div v-if="userTrainingLists.length > 0">
                <div v-for="(item, index) in userTrainingLists" :key="index">
                  <div v-if="!item.is_expired && !item.is_non_renewal">
                    <div
                      style="
                        height: 40px;
                        background-color: #ebf5fb;
                        margin-top: 20px;
                      "
                    >
                      <span
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 12px;
                          line-height: 15px;
                          text-transform: uppercase;
                          color: #171822;
                        "
                      >
                        <b-row>
                          <span
                            class="ml-2 mt-1"
                            style="
                              padding-bottom: 5px;
                              font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 12px;
                              line-height: 15px;
                              text-transform: uppercase;
                              color: #171822;
                            "
                          >
                            {{ item.training_name }}</span
                          >

                          <span
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            class="text-primary ml-auto mr-2"
                          >
                            <b-button
                              class="btn-icon font-weight-bold"
                              variant="white"
                              @click="openEditeForm(item)"
                            >
                              <feather-icon
                                v-b-tooltip.hover.top="'Edit'"
                                icon="EditIcon"
                                size="20"
                                class="text-primary"
                              />
                            </b-button>
                          </span>
                        </b-row>
                      </span>
                    </div>

                    <b-row>
                      <feather-icon
                        icon="CheckCircleIcon"
                        size="20"
                        class="text-primary m-1"
                      />

                      <span
                        class="mt-1"
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 21px;
                          color: #171822;
                        "
                      >
                        Completed on
                        {{ momentFormat(item.start_date, "MMM DD, YYYY") }}
                        <br />

                        <span
                          style="
                            font-family: Mulish;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #949494;
                          "
                        >
                          Expires on
                          {{ momentFormat(item.expire_date, "MMM DD, YYYY") }}
                        </span>
                      </span>

                      <span
                        v-if="item.training_certificate"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mr-2 mt-1"
                        @click="downloadFile(item.training_certificate)"
                      >
                        View Credential
                      </span>
                      <span
                        v-else
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mr-2 mt-1"
                        @click="noSuchDocument"
                      >
                        <!-- View Credential -->
                      </span>
                    </b-row>
                  </div>

                  <div v-if="item.is_expired && !item.is_non_renewal">
                    <div
                      style="
                        height: 40px;
                        background-color: #ebf5fb;
                        margin-top: 20px;
                      "
                    >
                      <span
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 12px;
                          line-height: 15px;
                          text-transform: uppercase;
                          color: #171822;
                        "
                      >
                        <b-row>
                          <span
                            class="ml-2 mt-1"
                            style="
                              padding-bottom: 5px;
                              font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 12px;
                              line-height: 15px;
                              text-transform: uppercase;
                              color: #171822;
                            "
                          >
                            {{ item.training_name }}</span
                          >

                          <span
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            class="text-primary ml-auto mr-2"
                          >
                            <b-button
                              class="btn-icon font-weight-bold"
                              variant="white"
                              @click="openEditeForm(item)"
                            >
                              <feather-icon
                                v-b-tooltip.hover.top="'Edit'"
                                icon="EditIcon"
                                size="20"
                                class="text-primary"
                              />
                            </b-button>
                          </span>
                        </b-row>
                      </span>
                    </div>

                    <b-row>
                      <feather-icon
                        icon="AlertOctagonIcon"
                        size="20"
                        class="text-danger m-1"
                      />

                      <span
                        class="mt-1"
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 21px;
                          color: #171822;
                        "
                      >
                        Completed on
                        {{ momentFormat(item.start_date, "MMM DD, YYYY") }}
                        <br /> 
                      
                       <div v-if="momentFormat(item.expire_date) >= momentFormat(today)">
                          <span
                          style="
                            font-family: Mulish;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #949494;
                          "
                        >
                          Expired on {{ momentFormat(item.expire_date, "MMM DD, YYYY") }}</span>
                          </div>
                          <div v-else>
                          <span
                          style="
                            font-family: Mulish;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #ff0000;
                          "
                        >
                          Expired on {{ momentFormat(item.expire_date, "MMM DD, YYYY") }}</span>
                          </div>
                          </span>

                      <span
                        v-if="item.training_certificate"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mr-2 mt-1"
                        @click="downloadFile(item.training_certificate)"
                      >
                        View Credential
                      </span>
                      <span
                        v-else
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mr-2 mt-1"
                        @click="noSuchDocument"
                      >
                        View Credential
                      </span>
                    </b-row>
                  </div>

                  <div v-if="item.is_non_renewal">
                    <div
                      style="
                        height: 40px;
                        background-color: #ebf5fb;
                        margin-top: 20px;
                      "
                    >
                      <span
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 12px;
                          line-height: 15px;
                          text-transform: uppercase;
                          color: #171822;
                        "
                      >
                        <b-row>
                          <span
                            class="ml-2 mt-1"
                            style="
                              padding-bottom: 5px;
                              font-family: Mulish;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 12px;
                              line-height: 15px;
                              text-transform: uppercase;
                              color: #171822;
                            "
                          >
                            {{ item.training_name }}</span
                          >

                          <span
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            class="text-primary ml-auto mr-2"
                          >
                            <b-button
                              class="btn-icon font-weight-bold"
                              variant="white"
                              @click="openEditeForm(item)"
                            >
                              <feather-icon
                                v-b-tooltip.hover.top="'Edit'"
                                icon="EditIcon"
                                size="20"
                                class="text-primary"
                              />
                            </b-button>
                          </span>
                        </b-row>
                      </span>
                    </div>

                    <b-row>
                      <svg
                        width="18"
                        height="18"
                        class="mt-1 ml-1"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 1.5V4.5"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 13.5V16.5"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.69727 3.69531L5.81977 5.81781"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.1797 12.1797L14.3022 14.3022"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.5 9H4.5"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.5 9H16.5"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.69727 14.3022L5.81977 12.1797"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.1797 5.81781L14.3022 3.69531"
                          stroke="#EFA12C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span
                        class="mt-1 ml-1"
                        style="
                          font-family: Mulish;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 21px;
                          color: #171822;
                        "
                      >
                        Start on {{ momentFormat(item.start_date, "MMM DD, YYYY") }}<br />
                        <span
                          style="
                            font-family: Mulish;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #949494;
                          "
                        >
                          Non-renewal</span
                        >
                      </span>
                      <span
                        v-if="item.training_certificate"
                        v-b-tooltip.hover.top="'Download certificate'"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="text-primary ml-auto mr-2 mt-1"
                        @click="downloadFile(item.training_certificate)"
                      >
                        View Credential
                      </span>
                    </b-row>
                  </div>
                </div>
              </div>

              <div v-else class="text-center mt-4">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z"
                    fill="#CCE6F4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z"
                    fill="white"
                  />
                  <path
                    d="M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z"
                    fill="black"
                  />
                  <path
                    d="M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z"
                    fill="black"
                  />
                  <path
                    d="M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z"
                    fill="black"
                  />
                  <path
                    d="M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z"
                    fill="black"
                  />
                  <path
                    d="M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z"
                    fill="#1B9AAA"
                  />
                  <path
                    d="M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z"
                    fill="#1B9AAA"
                  />
                  <path
                    d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                    fill="#1B9AAA"
                    stroke="#1B9AAA"
                    stroke-width="0.5"
                  />
                  <mask
                    id="mask0_1872_13634"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="14"
                    y="11"
                    width="6"
                    height="5"
                  >
                    <path
                      d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1872_13634)">
                    <path
                      d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                      fill="#1B9AAA"
                    />
                    <mask
                      id="mask1_1872_13634"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="14"
                      y="11"
                      width="6"
                      height="5"
                    >
                      <path
                        d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask1_1872_13634)"></g>
                  </g>
                </svg>

                <h5 class="mt-2">No Training Users</h5>
                <div style="height: 100px"></div>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <AddMandatoryTrainingPopup
      ref="addMandatoryTrainingPopup"
      @getAllData="getTrainingCertificate()"
    />

    <EditMandatoryTrainingPopup
      ref="editMandatoryTrainingPopup"
      @getAllData="getTrainingCertificate()"
    />

    <AddDocumentSidebar
      ref="AddDocumentSidebar"
      :is-add-document-sidebar-active="isAddDocumentSidebarActive"
      @getDocumentList="getDocumentList()"
    />

    <AddMandatorySidebar
      ref="AddMandatorySidebar"
      :is-add-mandatory-sidebar-active="isAddMandatorySidebarActive"
      @getAllData="toggleAddMandatorySideBar()"
    />

    <EditMandatorySidebar
      ref="EditMandatorySidebar"
      :is-edit-mandatory-sidebar-active="isEditMandatorySidebarActive"
      @getAllData="toggleEditMandatorySideBar()"
    />


  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BFormDatepicker,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { baseApiUrl, apiUrl } from "@/constants/config";
import fileUploader from "@/views/my-documents/fileUploader.js";
import flatPickr from "vue-flatpickr-component";
import AddMandatoryTrainingPopup from "../includes/popup/AddMandatoryTraining.vue";
import EditMandatoryTrainingPopup from "../includes/popup/EditMandatoryTraining.vue";
// import fileUploader from './fileUploader.js'

import profile from "@/apis/modules/profile";
import staffBank from "@/apis/modules/staffBank";
import MomentMixin from "@/mixins/MomentMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import settings from "@/apis/modules/settings";
import staffBankAPI from "@/apis/modules/staffBank";
import AddDocumentSidebar from "../sidebars/addDocumentSidebar.vue";
import AddMandatorySidebar from '../sidebars/addMandatorySidebar.vue';
import EditMandatorySidebar from '../sidebars/editMandatorySidebar.vue'

export default {
  name: "documentsAndTraining",
  components: {
    flatPickr,
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    AddMandatorySidebar,
    EditMandatorySidebar,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    AddMandatoryTrainingPopup,
    EditMandatoryTrainingPopup,
    VBTooltip,
    AddDocumentSidebar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data() {
    return {
      loadingModel: false,
      loadDocument: false,
      showAddMandatoryTraining: false,
      isAddDocumentSidebarActive: false,
      isAddMandatorySidebarActive: false,
      isEditMandatorySidebarActive: false,
      isActiveEditMandatoryTrainingForm: true,
      openEditMandatoryTrainingForm: false,
      fileTypeOption: [],
      selected_file_type_id: "",
      isActive: true,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: "",
      today: new Date(),
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      current_user_id: this.$route.params.id,
      userTrainingLists: [],
      getDocuments: [],
      resewal_options: [
        {
          key: "months-12",
          value: "months-6",
          name: "months 12",
        },
        {
          key: "months-24",
          value: "months-6",
          name: "months 24",
        },
        {
          key: "months-36",
          value: "months-6",
          name: "months 36",
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filters: {},
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "name",
          label: "DOCUMENT NAME",
          sortable: true,
        },
        {
          key: "file_type",
          label: "DOCUMENT TYPE",
          sortable: false,
        },

        {
          key: "created_at",
          label: "DATE UPLOADED",
          sortable: true,
        },
        {
          key: "updated_by",
          label: "UPLOADED BY",
          sortable: false,
        },

        {
          key: "action",
          label: "",
        },
      ],
      items: [],
    };
  },

  watch: {
    uploadedFile() {
      this.isActive = false;
    },
  },

  methods: {
    openDocumentSideBar() {
      this.$refs.AddDocumentSidebar.openSideBar();
    },

     toggleAddMandatorySideBar(){
        this.getTrainingCertificate();
        this.$refs.AddMandatorySidebar.openSideBar()
        this.isAddMandatorySidebarActive = !this.isAddMandatorySidebarActive;
    },

    toggleEditMandatorySideBar(){
        this.getTrainingCertificate();
        this.$refs.EditMandatorySidebar.openSideBar()
        this.isEditMandatorySidebarActive = !this.isEditMandatorySidebarActive;
    },

    tableLoad(){
      this.loadingModel = true;
      this.loadingModel = false;
    },
    
    
    formHandle() {
      this.formDisplay = !this.formDisplay;
    },

    openEditeForm(val) {
      this.$refs.EditMandatorySidebar.openSideBar(val)
      this.isEditMandatorySidebarActive = !this.isEditMandatorySidebarActive;
        
      // this.$refs.editMandatoryTrainingPopup.open(val);
      // this.openEditMandatoryTrainingForm = val;
    },

    openAddMandatoryTrainingPopup() {
      this.$refs.addMandatoryTrainingPopup.open();
    },

    async getFileType() {
      try {
        this.tableLoading = true;

        const Response = await settings.getFilteredFileType(
          this.current_user_id
        );
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    async getDocumentList() {
      try {
        this.loadDocument = true;

        if (this.sortBy === "") {
          this.sortDesc = true;
        }

        const Response = await staffBank.getFileListUserWiseList(
          this.current_user_id,
          this.currentPage,
          15,
          this.filterQuery,
          this.sortBy,
          this.sortDesc
        );
        this.getDocuments = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          updated_by: x.updated_by.first_name + " " + x.updated_by.last_name,
          // created_at: this.momentFormat(x.created_at, "dddd DD-MM-YYYY"),
          file_type: x.mime_type,
          type: x.file_type.name,
        }));
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.loadDocument = false;

        return this.getDocuments;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadDocument = false;
      }
      this.loadDocument = false;
    },
    async downloadFile(item) {
      try {
        this.loadingModel = true;

        const response = await staffBank.downloadCertificate(item.id);
        axios
          .get(
            `${apiUrl}/settings/training-certificates/${
              item.id
            }/download?auth_practice_id=${sessionStorage.getItem(
              "practice_id"
            )}`,
            {
              // headers{
              // }
              responseType: "arraybuffer",
              withCredentials: true,
            }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: item.mime_type }),
              link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = item.name;
            link.click();
          });
        this.showSuccessMessage("File Downloaded Successfully");
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadingModel = false;
      }
      this.loadingModel = false;
    },
    async deleteFile(item) {
      try {
        this.loadDocument = true;
        this.$swal({
          title: "Are you sure you want to delete this document?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await staffBank.deleteToFile(item.id);
            this.getDocumentList();
          } else if (result.dismiss === "cancel") {
            this.loadDocument = false;
          }
        });
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadDocument = false;
      }
      this.loadDocument = false;
      this.getDocumentList();
    },
    async downloadDocumentFile(item) {
      try {
        this.loadDocument = true;

        const response = await staffBank.downloadImage(item.id);
        axios
          .get(
            `${apiUrl}/salaried-staff/files/${
              item.id
            }/download?auth_practice_id=${sessionStorage.getItem(
              "practice_id"
            )}`,
            {
              // headers{
              // }
              responseType: "arraybuffer",
              withCredentials: true,
            }
          )
          .then((response) => {
           let blob = new Blob([response.data], { type: item.mime_type }),
              link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = item.name;
            link.click();
          });
        this.showSuccessMessage("File Downloaded Successfully");
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadDocument = false;
      }
      this.loadDocument = false;
    },
    resetData() {
      this.selected_file_type_id = "";
      this.uploadedFile = "";
    },

    async uploadFile() {
      try {
        this.buttonLoading = true;
        fileUploader
          .store(this.uploadedFile, {
            progress: (progress) => {
              this.uploadProgress = Math.round(progress * 100);
            },
            visibility: "public-read",
            // baseURL: 'http://hyre-be-v2.test/api/mp',
            baseURL: `${baseApiUrl}`,

            params: {
              auth_practice_id: `${sessionStorage.getItem("practice_id")}`,
            },
          })
          .then(async (response) => {
            const key = response.key;
            await this.setImageKeyToApi(key);
            await this.resetData();
            this.isActive = true;
          });
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async setImageKeyToApi(key) {
      await staffBank.getImage({
        key: key,
        name: this.uploadedFile.name,
        file_type_id: this.selected_file_type_id,
      });
      this.showSuccessMessage("Document Uploaded Successfully");
      this.buttonLoading = false;
      this.$refs.table.refresh();
      this.showFileUploadModal = false;
    },

    async getTrainingCertificate() {
      this.loadingModel = true;
      try {
        let user_id = this.$route.params.id;
        let respond = (await staffBankAPI.getAllUserTraining(user_id)).data
          .data;

        this.userTrainingLists = respond.map((x) => ({
          id: x.id,
          training_id: x.trainings.id,
          training_name: x.trainings.name,
          is_non_renewal: x.is_non_renewal,
          start_date: x.start_date,
          expire_date: x.expire_date,
          training_certificate: x.trainingcertificate,
          is_expired:
            this.momentFormat(x.start_date, "MMM DD, YYYY") <
            this.momentFormat(new Date(), "MMM DD, YYYY"),
          selected_training:x.trainings
        }));
        // console.log(this.momentFormat(new Date(), "MMM DD, YYYY"))
        // console.log(this.userTrainingLists)
      } catch (error) {}
      this.loadingModel = false;
    },

    openFileUploadModel() {
      try {
        this.showFileUploadModal = true;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    noSuchDocument() {
      this.showErrorMessage("No Certificate");
    },

    filterQueryUpdate() {
      this.$refs.table.refresh();
    },
  },
  async mounted() {
    await this.getDocumentList();
    await this.getTrainingCertificate();
  },
};
</script>

<style scoped></style>
